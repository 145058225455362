<template>
    <router-link :to="'/' + location() + '/' + history.id" class="history" v-ripple :style="{'background-image': `url(${history.type == 'live' && history.details.country !== '24/7' ? '' : history.logos[0]})`, 'background-color': history.color }" :class='size'>
        <img :src="history.logos[0]" v-if="history.type == 'live' && history.details.country !== '24/7'"/>
        <p> {{history.name}} </p>
    </router-link>
</template>

<script>

export default {
  name: 'history',
  props: {
    history: Object,
    size: {
        type: String,
        default: function(){ return ''}
    }
  },

  computed: { },

  data: function(){
      return {}
  },

  methods: {
    location(){
        if(this.history.type == 'live'){
            return 'channel'
        }
        if(this.history.type == 'od'){
            return this.history.details.contentType
        }

        return this.history.type
    }
  },

  mounted: function(){
  }
}
</script>

<style scoped lang="scss">
.history{
    position: relative;
    border-radius: 5px;
    background-size: cover;
    background-position: center;
    height: 300px;
    width: 100vw;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    p{
        margin: 0;
        padding: 0;
        height: 0;
        opacity:0;
    }
    img{
        max-height: 50%;
        max-width: 50%;
    }
    &.fit{
        width: 100%;
        height: 100%;
    }
 }
</style>
